import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/nextjs';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { isPast, subSeconds } from 'date-fns';
import decodeJwt from 'jwt-decode';
import type { createNetworkStatusNotifier } from 'react-apollo-network-status';

import introspection from './generated/introspection';
import { handleRefreshTokenResponse } from './hooks/auth/useRefreshAccessToken';
import REFRESH_ACCESS_TOKEN_MUTATION from './mutations/auth/refreshAccessToken';
import {
  loadAccessToken,
  loadRefreshToken,
  removeAccessToken,
  removeRefreshToken,
} from './storage/tokens';
import globalAlternateStates from './variables/error';
import initVar from './variables/init';

async function waitForInitialization(): Promise<void> {
  const init = initVar();

  // If it is initialized, we should continue
  if (init) {
    return;
  }

  // Else, we will wait for the next change of initVar
  await new Promise<void>((resolve) => {
    initVar.onNextChange(() => {
      resolve();
    });
  });

  // Once the initVar has change, we call this function
  // recursively to check if its value is already true
  await waitForInitialization();
}

async function getAccessToken(
  refreshClient: ApolloClient<unknown>,
): Promise<string | undefined> {
  const accessToken = loadAccessToken();
  const refreshTokenLoading = loadRefreshToken();

  if (!accessToken && !refreshTokenLoading) {
    return undefined;
  }

  const refreshToken = refreshTokenLoading as string;

  const expiration = new Date(
    decodeJwt<{ exp: number }>(accessToken as string).exp * 1000,
  );

  // If the token has expired or is about to (to protect against race
  // conditions), renew it.
  const isExpired = isPast(subSeconds(expiration, 30));

  if (!isExpired) {
    return accessToken;
  }

  try {
    const response = await refreshClient.mutate({
      mutation: REFRESH_ACCESS_TOKEN_MUTATION,
      variables: {
        refreshToken,
      },
    });

    handleRefreshTokenResponse(response.data);

    if (
      response.data?.refreshAccessToken.__typename ===
        'AuthenticationPayload' &&
      response.data?.refreshAccessToken.accessToken
    ) {
      return response.data?.refreshAccessToken.accessToken;
    }
    return undefined;
    // in case of any error, remove the tokens and sign the user out
  } catch {
    removeRefreshToken();
    removeAccessToken();
    return undefined;
  }
}

export default function setupClient({
  statusNotifierLink,
  showError,
}: {
  statusNotifierLink: ReturnType<typeof createNetworkStatusNotifier>['link'];
  showError: () => void;
}): ApolloClient<unknown> {
  // A little bit of a hack with the types, they were a bit off between the http and upload for the linter
  const httpLink = createUploadLink({
    uri: process.env['NEXT_PUBLIC_GRAPHQL_ENDPOINT'],
  }) as unknown as ApolloLink;

  const refreshClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
  });

  const initLink = setContext(async ({ operationName }, { headers = {} }) => {
    if (operationName === 'RefreshAccessToken') {
      return {
        // This is any-typed in apollo, nothing we can do
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers,
      };
    }

    await waitForInitialization();

    return {
      // This is any-typed in apollo, nothing we can do
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers,
    };
  });

  const authLink = setContext(async (_, { headers = {} }) => {
    const accessToken = await getAccessToken(refreshClient);

    if (!accessToken) {
      // This is any-typed in apollo, nothing we can do
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return headers;
    }

    const authHeader = accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {};

    return {
      // This is any-typed in apollo, nothing we can do
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: {
        ...headers,
        ...authHeader,
      },
    };
  });

  const handleError = (operation: Operation) => {
    // Check if error has been handled, this has to be added to the mutations if they don't want to automatically be handled by 'showError'
    if (!operation.getContext()['errorHandled']) {
      showError();
    }
  };

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      if (
        graphQLErrors.some(
          (e) => e.message === 'Unknown or invalid refresh token.',
        )
      ) {
        globalAlternateStates({
          invalidRefreshToken: true,
        });
      } else if (graphQLErrors.some((e) => e.message === 'Permission Denied')) {
        globalAlternateStates({
          permissionDenied: true,
        });
      } else {
        handleError(operation);
        graphQLErrors.forEach((e) => {
          Sentry.captureException(e.message);
          datadogRum.addError(e.message);
        });
      }
    } else if (networkError) {
      handleError(operation);
      Sentry.captureException(networkError);
      datadogRum.addError(networkError);
    }
  });

  const link = from([
    errorLink,
    statusNotifierLink,
    initLink,
    authLink,
    httpLink,
  ]);

  // Initialize ApolloClient
  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: introspection.possibleTypes,
    }),
    link,
    // only connect to devtools in non-prod environments
    connectToDevTools: process.env.NODE_ENV !== 'production',
  });
}
